var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"userForm","visible":_vm.dialog,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"change":function (val) { return _vm.visibility(val); }}},[[_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[(_vm.newForm)?_c('h5',{staticClass:"mb-0"},[_vm._v("Crear nuevo usuario")]):_c('h5',{staticClass:"mb-0"},[(_vm.formDisabled)?_c('span',[_vm._v("Detalle de usuario")]):_c('span',[_vm._v("Editar usuario")])]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":_vm.closeDialog}})],1),_c('validation-observer',{ref:"formData"},[_c('b-form',{staticClass:"auth-login-form p-2",on:{"submit":function($event){$event.preventDefault();_vm.newForm ? _vm.store() : _vm.edit()},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('validation-provider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"label":"Nombre","label-for":"fullName"}},[_c('b-form-input',{attrs:{"id":"fullName","state":errors.length > 0 ? false : null,"trim":"","autofocus":"","disabled":_vm.formDisabled},model:{value:(_vm.formData.fullName),callback:function ($$v) {_vm.$set(_vm.formData, "fullName", $$v)},expression:"formData.fullName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"label":"Email","label-for":"email"}},[_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false : null,"trim":"","disabled":_vm.formDisabled},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Roles","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"label":"Roles","label-for":"role"}},[_c('v-select',{attrs:{"id":"role","options":_vm.roleOptions,"reduce":function (val) { return val.id; },"label":"name","state":errors.length > 0 ? false : null,"trim":"","clearable":"","multiple":"","disabled":_vm.formDisabled},model:{value:(_vm.formData.role),callback:function ($$v) {_vm.$set(_vm.formData, "role", $$v)},expression:"formData.role"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Clientes","rules":_vm.isCustomer ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"label":"Clientes","label-for":"Customers"}},[_c('v-select',{attrs:{"id":"customers","options":_vm.customersOptions,"label":"name","state":errors.length > 0 ? false : null,"trim":"","clearable":"","multiple":"","disabled":_vm.formDisabled},model:{value:(_vm.formData.customers),callback:function ($$v) {_vm.$set(_vm.formData, "customers", $$v)},expression:"formData.customers"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),(!_vm.newForm)?_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"checkbox-1","name":"checkbox-1","value":"accepted","unchecked-value":"not_accepted"},model:{value:(_vm.formData.resend_password),callback:function ($$v) {_vm.$set(_vm.formData, "resend_password", $$v)},expression:"formData.resend_password"}},[_vm._v(" Reenviar Contraseña ")])],1):_vm._e(),(_vm.newForm)?_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Contraseña")])]),_c('validation-provider',{attrs:{"name":"Contraseña","vid":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"password","placeholder":"Contraseña"},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2733079207)})],1):_vm._e(),(_vm.newForm)?_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"confirm-password"}},[_vm._v("Confirmar contraseña")])]),_c('validation-provider',{attrs:{"name":"Confirmar contraseña","rules":"required|confirmed:Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"c_password","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"c_password","placeholder":"Confirmar contraseña"},model:{value:(_vm.formData.c_password),callback:function ($$v) {_vm.$set(_vm.formData, "c_password", $$v)},expression:"formData.c_password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3429479258)})],1):_vm._e()],1)],1),(!_vm.formDisabled)?_c('b-button-toolbar',{staticClass:"p-2",attrs:{"justify":""}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":function($event){_vm.newForm ? _vm.store() : _vm.edit()}}},[_vm._v(" Guardar ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-secondary"},on:{"click":_vm.closeDialog}},[_vm._v(" Cancelar ")])],1):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }