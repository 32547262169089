<template>
  <b-sidebar
    id="userForm"
    :visible="dialog"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => visibility(val)"
  >
    <template>
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 v-if="newForm" class="mb-0">Crear nuevo usuario</h5>
        <h5 v-else class="mb-0">
          <span v-if="formDisabled">Detalle de usuario</span>
          <span v-else>Editar usuario</span>
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="closeDialog"
        />
      </div>

      <validation-observer ref="formData">
        <!-- Form -->
        <b-form
          class="auth-login-form p-2"
          @submit.prevent="newForm ? store() : edit()"
          @reset.prevent="resetForm"
        >
          <!-- Name -->
          <validation-provider
            #default="{ errors }"
            name="Nombre"
            rules="required"
          >
            <b-form-group
              label="Nombre"
              label-for="fullName"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="fullName"
                v-model="formData.fullName"
                :state="errors.length > 0 ? false : null"
                trim
                autofocus
                :disabled="formDisabled"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="{ errors }"
            name="Email"
            rules="required|email"
          >
            <b-form-group
              label="Email"
              label-for="email"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="email"
                v-model="formData.email"
                :state="errors.length > 0 ? false : null"
                trim
                :disabled="formDisabled"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Roles -->
          <validation-provider
            #default="{ errors }"
            name="Roles"
            rules="required"
          >
            <b-form-group
              label="Roles"
              label-for="role"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <v-select
                id="role"
                v-model="formData.role"
                :options="roleOptions"
                :reduce="(val) => val.id"
                label="name"
                :state="errors.length > 0 ? false : null"
                trim
                clearable
                multiple
                :disabled="formDisabled"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Customers -->
          <validation-provider
            v-slot="{ errors }"
            name="Clientes"
            :rules="isCustomer ? 'required' : ''"
          >
            <b-form-group
              label="Clientes"
              label-for="Customers"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <v-select
                id="customers"
                v-model="formData.customers"
                :options="customersOptions"
                label="name"
                :state="errors.length > 0 ? false : null"
                trim
                clearable
                multiple
                :disabled="formDisabled"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <b-form-group v-if="!newForm">
            <b-form-checkbox
              id="checkbox-1"
              v-model="formData.resend_password"
              name="checkbox-1"
              value="accepted"
              unchecked-value="not_accepted"
            >
              Reenviar Contraseña
            </b-form-checkbox>
            <!-- Password -->
          </b-form-group>
          <b-form-group v-if="newForm">
            <div class="d-flex justify-content-between">
              <label for="password">Contraseña</label>
            </div>
            <validation-provider
              #default="{ errors }"
              name="Contraseña"
              vid="Password"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="password"
                  v-model="formData.password"
                  :state="errors.length > 0 ? false : null"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  name="password"
                  placeholder="Contraseña"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Confirm Password -->
          <b-form-group v-if="newForm">
            <div class="d-flex justify-content-between">
              <label for="confirm-password">Confirmar contraseña</label>
            </div>
            <validation-provider
              #default="{ errors }"
              name="Confirmar contraseña"
              rules="required|confirmed:Password"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="c_password"
                  v-model="formData.c_password"
                  :state="errors.length > 0 ? false : null"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  name="c_password"
                  placeholder="Confirmar contraseña"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
      <b-button-toolbar v-if="!formDisabled" justify class="p-2">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          @click="newForm ? store() : edit()"
        >
          Guardar
        </b-button>

        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-secondary"
          @click="closeDialog"
        >
          Cancelar
        </b-button>
      </b-button-toolbar>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
  BFormCheckboxGroup,
  BButtonToolbar,
  BFormTextarea,
  BFormSelect,
  BFormSelectOption,
  BInputGroupAppend,
  BInputGroup,
} from "bootstrap-vue";

import es from "vee-validate/dist/locale/es";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { required, required_if } from "@validations";
export default {
  name: "UserForm",
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BButtonToolbar,
    BFormTextarea,
    BFormSelect,
    BFormSelectOption,
    BInputGroupAppend,
    BInputGroup,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  directives: {
    Ripple,
  },
  data() {
    return {
      formData: {
        id: null,
        name: "",
        email: "",
        role: [],
        customers: [],
        password: "",
        c_password: "",
        resend_password: false,
      },
      newForm: true,
      formDisabled: false,
      dialog: false,
      doc_types: ["NIT", "RUT", "CC"],
      customer_groups: [],
      roleOptions: [],
      customersOptions: [],
      isCustomer: true,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  created() {
    this.fetchRoles();
  },
  methods: {
    visibility(val) {
      if (!val) this.dialog = false;
      localize("es", es);
    },
    closeDialog() {
      this.dialog = false;
      this.resetForm();
      this.$refs.formData.reset();
    },
    fetchRoles() {
      this.$http.get("roles/list").then((response) => {
        this.roleOptions = response.data.roles;
      });
    },
    store() {
      localize("es", es);
      this.formData.customersId = []
      this.formData.customers.forEach(element => {
        this.formData.customersId.push(element.id)
      }); 
      this.formData.customers = this.formData.customersId
      this.$refs.formData.validate().then((success) => {
        if (success) {
          this.$http
            .post("/users", this.formData)
            .then((response) => {
              this.$emit("refeshTable");
              this.closeDialog();
              this.$refs.formData.reset();
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Usuario creado`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `Usuario ${response.data.user.name} creado con éxito`,
                },
              });
            })
            .catch((error) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Error`,
                  icon: "AlertOctagonIcon",
                  variant: "danger",
                  text: error.response.data.message,
                },
              });
            });
        }
      });
    },
    edit() {
      localize("es", es);
      this.$refs.formData.validate().then((success) => {
        if (success) {
          console.log(this.formData);
          this.formData.customers = this.formData.customers.map(
            (key) => key.id
          );
          this.$http
            .put(`/users/${this.formData.id}`, this.formData)
            .then((response) => {
              this.$emit("refeshTable");
              this.closeDialog();
              this.$refs.formData.reset();
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Cliente actualizado`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `Cliente ${response.data.user.name} actualizado con éxito`,
                },
              });
            })
            .catch((error) => {
              console.log("error");
            });
        }
      });
    },
    resetForm() {
      this.formData = {
        id: null,
        name: "",
        email: "",
        roles: [],
        customers: [],
        password: "",
        c_password: "",
      };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/pages/page-auth.scss";
</style>

<style scoped>
.vs__selected {
  color: #000 !important;
}
</style>
