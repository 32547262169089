<template>
  <div>
      <b-card
      no-body
      class="mb-0"
      >
          <div class="m-2">
              <b-row>
                  <b-col cols="12" md="3" class="">
                      <label>Mostrar</label>
                      <v-select v-model="tableSettings.perPage" :options="perPageOptions" :clearable="false"
                      class="per-page-selector d-inline-block mx-50" />
                      <label>registros</label>
                  </b-col>
                  
                  <b-col cols="12" md="9">
                      <div class="d-flex align-items-center justify-content-end">
                          <b-button variant="primary" class="btn-icon mr-1" @click="createUser">
                              <feather-icon icon="PlusIcon" /> Crear
                          </b-button>
                      </div>
                  </b-col>
              </b-row>
          </div>
          <b-table
          :busy="tableSettings.busy"
          ref="refRoleListTable"
          class="position-relative"
          :items="dataTable"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="tableSettings.sortBy"
          show-empty
          empty-text="No se encontraron datos"
          :sort-desc.sync="tableSettings.sortDesc"
      >
          <template #cell(role)="data">
            <div v-for="(item, index) in data.item.role" :key="index">
              <span>{{ item }}</span>
            </div>
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
          >

              <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
              </template>
              <b-dropdown-item @click="detailUser(data.item)">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Detalle</span>
              </b-dropdown-item>

              <b-dropdown-item @click="editUser(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
              </b-dropdown-item>

              <b-dropdown-item @click="deleteUser(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Eliminar</span>
              </b-dropdown-item>
          </b-dropdown>
          </template>

          </b-table>
          <div class="mx-2 mb-2">
              <b-row>
      
              <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                  <span class="text-muted">Viendo del {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
      
                  <b-pagination
                  v-model="tableSettings.page"
                  :total-rows="totalRows"
                  :per-page="tableSettings.perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                  >
                  <template #prev-text>
                      <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                      />
                  </template>
                  <template #next-text>
                      <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                      />
                  </template>
                  </b-pagination>
      
              </b-col>
      
              </b-row>
          </div>

      </b-card>
      <user-form 
          @refeshTable="getTableData" ref="UserForm"
      />
  </div>
</template>

<script>
import { BContainer, BRow, BCol, BCard, BButton, BTable, BPagination, BDropdown, BDropdownItem } from 'bootstrap-vue';
import UserForm from './UserForm.vue'
import vSelect from 'vue-select'

export default {
  name: 'Users',
  components: { BContainer, BRow, BCol, BCard, BButton, BTable, BPagination, vSelect, UserForm, BDropdown, BDropdownItem },
  data() {
      return {
          formDisabled: false,
          isAddNewSidebarActive: false,
          refRoleListTable: null,
          perPageOptions: [10, 25, 50, 100],
          searchQuery: '',
          inputs: [
              { value: 'id', title: 'Id' },
              { value: 'name', title: 'Nombre' },
              { value: 'email', title: 'Correo' },
              { value: 'email', title: 'Correo' },
          ],
          typeSearch: [
              { value: 'LIKE', title: 'Igual' },
              { value: 'NOT LIKE', title: 'No es igual' },
              { value: '>', title: 'Mayor que' },
              { value: '<', title: 'Menor que' }
          ],
          tableColumns: [
              { key: 'fullName', label: 'Nombre', sortable: true },
              { key: 'email', label: 'Correo', sortable: true },
              { key: 'role', label: 'Roles', sortable: true },
              { key: 'actions', label: 'acciones' },
          ],
          sortBy: 'id',
          isSortDirDesc: true,
          totalRows: 0,
          dataMeta: {
              from: 0,
              to: 0,
              of: 0,
          },
          dataTable: [],
          tableSettings: {
              filter: this.$route.params && this.$route.params.id,
              searchQuery: '',
              input: '',
              typeSearch: '',
              perPage: 10,
              page: 1,
              sortBy: 'id',
              sortDesc: true,
              busy: false
          },
          customersOptions: [],
      }
  },
  methods: {
      createUser() {
          this.$refs.UserForm.resetForm();
          this.$refs.UserForm.newForm = true;
          this.$refs.UserForm.formDisabled = false;
          this.$refs.UserForm.dialog = true;
          this.$refs.UserForm.customersOptions = this.customersOptions;
      },
      editUser(user) {
          this.$refs.UserForm.resetForm();
          this.$refs.UserForm.newForm = false;
          this.$refs.UserForm.formDisabled = false;
          this.$refs.UserForm.formData = user;
          this.$refs.UserForm.dialog = true;
          this.$refs.UserForm.customersOptions = this.customersOptions;
      },
      detailUser(user) {
          this.$refs.UserForm.resetForm();
          this.$refs.UserForm.newForm = false;
          this.$refs.UserForm.formDisabled = true;
          this.$refs.UserForm.formData = user;
          this.$refs.UserForm.dialog = true;
          this.$refs.UserForm.customersOptions = this.customersOptions;
      },
      async getTableData() {
          this.tableSettings.busy = true;
          await this.$http.get('/users', { params: this.tableSettings }).then((response) => {
              this.dataTable = response.data.users
              this.totalRows = response.data.total
              this.dataMetaCounter()
          })
          this.tableSettings.busy = false;
      },
      dataMetaCounter() {
          const localItemsCount = this.dataTable.length
          this.dataMeta.from = this.tableSettings.perPage * (this.tableSettings.page - 1) + (localItemsCount ? 1 : 0)
          this.dataMeta.to = this.tableSettings.perPage * (this.tableSettings.page - 1) + localItemsCount
          this.dataMeta.of = this.totalRows
      },
      deleteUser(user) {
          this.$swal({
              title: `¿Está séguro de eliminar el usuario ${user.fullName}?`,
              text: "Este proceso no se puede revertir",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Si, eliminar',
              cancelButtonText: 'Cancelar',
              customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
          }).then(async result => {
              if (result.value) {
                  this.tableSettings.busy = true;
                  await this.$http.delete(`/users/${user.id}`)
                  .then(() => {
                      this.getTableData()
                      this.$swal({
                          icon: 'success',
                          title: 'Usuario eliminado',
                          text: 'El usuario ha sido eliminado.',
                          customClass: {
                              confirmButton: 'btn btn-success',
                          },
                      })
                  })
                  this.tableSettings.busy = false;
              }
          })
      },
      async fetchCustomers() {
          await this.$http.get('/users/customers')
          .then((response) => {
              this.customersOptions = response.data.customersOptions
          })
      },
  },
  created(){
      this.getTableData();
      this.fetchCustomers();
  },
  watch: {
      "tableSettings.sortBy": {
          handler(val) {
              this.getTableData()
          },
      },
      "tableSettings.sortDesc": {
          handler(val) {
              this.getTableData()
          },
      },
      "tableSettings.perPage": {
          handler(val) {
              this.getTableData()
          },
      },
      "tableSettings.searchQuery": {
          handler(val) {
              this.getTableData()
          },
      },
      "tableSettings.page": {
          handler(val) {
              this.getTableData()
          },
      },
  }
} 
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>